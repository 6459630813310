<template>
  <div class="successBox">
    <div class="img"><img src="../assets/renzhengchenggong.png" alt="" /></div>
    <div>请确认您支付成功</div>
    <div style="display: flex" v-if="confirm">
      <div class="title1" @click="successBnt">确认</div>
      <div class="title1" @click="back">否</div>
    </div>
    <div v-if="successTitle">
      <div class="title">恭喜您平台认证成功</div>
      <div class="title1" @click="backHome">返回首页</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      successTitle: false,
      confirm: true,
    }
  },
  methods: {
    backHome() {
      this.$router.push("/Home")
    },
    back() {
      this.$router.go(-1)
    },
    successBnt() {
      this.successTitle = true
      this.confirm = false
    },
  },
}
</script>

<style lang="less" scoped>
.successBox {
  text-align: center;

  .img {
    margin-top: 44vw;
  }
  .title {
    font-size: 4vw;
    color: #000000;
    margin-top: 6.6667vw;
  }
  .title1 {
    width: 33.3333vw;
    height: 10vw;
    background: #0a49ff;
    border-radius: 10px;
    color: #ffffff;
    line-height: 10vw;
    margin: 10vw auto;
  }
}
</style>
